<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title w-100 flex-row">
        <div class="w-50">
          <h3 class="card-label font-weight-bolder text-dark w-100">
            <div>Óticas</div>
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1"
            >Listagem de óticas cadastras usando o OptiCert</span
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <StoreList />
    </div>
  </div>
</template>

<script>
import StoreList from '@/components/Stores/StoreList.vue'

export default {
  name: 'StorePage',
  components: { StoreList }
}
</script>
